<template>
	<div class="track-result">
		<div class="header flex-start-center">
			<img src="~@/assets/blue_logo.png" />
			<span class="title">友邦物联</span>
			<span class="desc">国际物流轨迹查询</span>
		</div>

		<div class="wrapper">
			<!-- 展示内容区 -->
			<div class="content">
				<!-- 选择标签 -->
				<a-tabs
					default-active-key="1"
					@change="tabChange"
					v-model="activeKey"
					class="tab"
					:tabBarGutter="20"
				>
					<a-tab-pane v-for="item in tabList" :key="item.status" :tab="item | tabTitle">
					</a-tab-pane>
				</a-tabs>

				<!-- 展示列表内容 -->
				<a-collapse default-active-key="1" v-if="displaySource.length" class="display-list">
					<a-collapse-panel
						v-for="item in displaySource"
						:key="item.trackNum"
						:disabled="item.result === 0"
					>
						<div slot="header" class="item-header">
							<a-row>
								<a-col :span="4">
									<span>{{ item.trackNum }}</span>
									<span class="secLine">{{ statusTitle(item.result) }}</span>
								</a-col>
								<a-col :span="6" class="middle">
									<div>
										<span>中国</span>
										<!-- <span class="secLine">China Post</span> -->
									</div>
									<img src="~@/assets/right_arrow.png" />
									<div>
										<span>{{ findCountryInfoByCode(item.country).name }}</span>
										<!-- <span class="secLine">
									{{ findCountryInfoByCode(item).en }} Post
								</span> -->
									</div>
								</a-col>
								<a-col :span="14">
									<div v-if="item.result === 0" class="right">
										您所提交的单号查询不到跟踪信息
									</div>
									<div v-else class="right">
										{{ item.latest.date }} {{ item.latest.status }}
									</div>
								</a-col>
							</a-row>
						</div>
						<div class="title">目的地</div>
						<div v-if="item.toDetail.length === 0" class="no-result">
							无响应
						</div>
						<a-timeline v-else>
							<a-timeline-item
								color="gray"
								v-for="(node, index) in item.toDetail"
								:key="index"
							>
								<span>{{ node.date }}</span>
								<span style="margin-left: 15px">{{ node.status }}</span>
							</a-timeline-item>
						</a-timeline>
						<div class="title">发送地</div>
						<div v-if="item.fromDetail.length === 0" class="no-result">
							无响应
						</div>
						<a-timeline v-else>
							<a-timeline-item
								:color="node.date === item.latest.date ? 'blue' : 'gray'"
								v-for="(node, index) in item.fromDetail"
								:key="index"
							>
								<span :class="{ islatest: node.date === item.latest.date }">{{
									node.date
								}}</span>
								<span
									style="margin-left: 15px"
									:class="{ islatest: node.date === item.latest.date }"
									>{{ node.status }}</span
								>
							</a-timeline-item>
						</a-timeline>
					</a-collapse-panel>
				</a-collapse>

				<div class="no-data" v-else>
					没有数据
				</div>
			</div>

			<!-- 重置选择区 -->
			<div class="search">
				<a-form-model
					ref="textAreaForm"
					:model="textAreaForm"
					v-bind="textAreaForm.layout"
					class="form"
				>
					<a-form-model-item
						v-for="(domain, index) in textAreaForm.domains"
						:key="index"
						@keyup.enter.native="onPressEnter($event, index)"
						class="flex-start"
					>
						<a-input
							v-model="domain.trackNum"
							class="input-item"
							v-focus="picFocusStatus"
							:id="`input${index}`"
							@click="clickInput"
							style="width: 100%;border: none"
						/>
						<a-icon
							class="delete-button"
							type="close-circle"
							@click="removeInput(domain)"
						/>
					</a-form-model-item>
				</a-form-model>

				<div class="search-bar">
					<a-button class="choose-btn" @click="showModal">
						<div v-if="selectCarrierItem">
							<img :src="selectCarrierItem.logo" alt="" />
							<span class="chinese-name">{{ selectCarrierItem.name }}</span>
						</div>
						<div v-else>选择物流商</div>
					</a-button>
					<a-button type="primary" class="search-btn" @click="handleNetSearch"
						>查询</a-button
					>
				</div>
			</div>
		</div>

		<!-- 弹窗 -->
		<a-modal v-model="modalVisible" :footer="null" width="864px" :closable="false">
			<div class="modal-header flex-between-center">
				<div class="left">请选择物流商</div>
				<div class="right">
					<a-input
						placeholder="输入物流商中文或英文名称"
						class="search-input"
						v-model="searchValue"
						@keyup.enter.native="handleSearch"
					></a-input>
					<div class="cancel-btn flex-end-center">
						<a-icon type="close" @click="hideModal" />
					</div>
				</div>
			</div>
			<div class="container">
				<template v-if="this.searchValue">
					<div class="pane-content">
						<div class="customer-list flex-start">
							<div
								class="customer-item"
								v-for="item in searchList"
								:key="item.title"
								@click="clickCarrierItem(item)"
							>
								<img :src="item.logo" class="customer-img" />
								<span class="title">{{ item.name }}</span>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<a-tabs :default-active-key="1" @change="clickTabs">
						<a-tab-pane v-for="item in modalTabList" :key="item.key" :tab="item.title">
						</a-tab-pane>
					</a-tabs>
					<div class="pane-content">
						<div class="customer-list flex-start">
							<div
								class="customer-item"
								v-for="item in renderList"
								:key="item.title"
								@click="clickCarrierItem(item)"
							>
								<img :src="item.logo" class="customer-img" />
								<span class="title">{{ item.name }}</span>
							</div>
						</div>
					</div>
				</template>
			</div>
		</a-modal>
	</div>
</template>

<script>
import trackApi from '@/api/track'
import countryList from '@/assets/country/country'
export default {
	name: 'TrackResult',
	data() {
		return {
			tabList: [
				{
					status: -1,
					title: '全部',
					count: 0,
				},
				{
					status: 0,
					title: '查询不到',
					count: 0,
				},
				{
					status: 8,
					title: '运输途中',
					count: 0,
				},
				{
					status: 30,
					title: '到达待取',
					count: 0,
				},
				{
					status: 20,
					title: '投递失败',
					count: 0,
				},
				{
					status: 50,
					title: '可能异常',
					count: 0,
				},
				{
					status: 40,
					title: '签收成功',
					count: 0,
				},
				{
					status: 65,
					title: '包裹退回',
					count: 0,
				},
			],
			dataSource: [], // 数据源
			// displaySource: [], // 展示源
			activeKey: -1, // 处于选中的tab

			// 右边搜索框
			textAreaForm: {
				layout: {
					labelCol: { span: 0 },
					wrapperCol: { span: 24 },
				},
				domains: [
					{
						trackNum: ''
					},
				],
			},
			picFocusStatus: true,
			modalVisible: false, // 显示弹窗
			modalTabList: [
				{
					key: 1,
					title: '全球邮政',
				},
				{
					key: 2,
					title: '国际物流商',
				},
				{
					key: 3,
					title: '国际物流商',
				},
			],
			customerList: [
				{
					url: '',
					title: '全球邮全球邮政1全球邮',
				},
				{
					url: '',
					title: '全球邮政',
				},
			],
			selectCarrierItem: null, // 选中的渠道商
			carriers: [], // 渠道列表
			china: [],
			international: [],
			globalPost: [],
			renderList: [], // 渲染列表
			searchValue: '',
			searchList: [], // 搜索结果列表
		}
	},
	mounted() {
		const data = localStorage.getItem('carriers')
		if (!data) {
			this.getCarriers()
		} else {
			this.setupCarriersData(JSON.parse(data))
        }
        
        const params = JSON.parse(this.$route.query.params)
        console.log(params);
        console.log(this.$route.query.selectCarrierItem);
        if (params) {
            this.loadData(params)
            this.textAreaForm.domains = params

            this.selectCarrierItem = JSON.parse(this.$route.query.selectCarrierItem)
        }
	},
	methods: {
		loadData(params) {
			this.dataSource = []
			this.$toast.show()
			trackApi
				.tracklist(params)
				.then((res) => {
					if (res.data.code === 2000) {
						this.dataSource = res.data.data.list

						// 将节点反转
						this.dataSource.forEach((element) => {
							element.fromDetail.reverse()
							element.toDetail.reverse()
						})

						this.setupTabCount()
					} else {
						this.$message.error(res.data.message)
					}
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					this.$toast.dismiss()
				})
		},

		// 拉取渠道商数据
		async getCarriers() {
			const res = await trackApi.carriers()
			if (res.data.code === 2000) {
				const data = res.data.data.data
				localStorage.setItem('carriers', JSON.stringify(data))
				this.setupCarriersData(data)
			} else {
				this.$message.error('网络异常')
			}
		},

		// 赋值渠道商数据
		setupCarriersData(data) {
			this.carriers = data
			for (let i of data) {
				if (i.type === 'global-post') {
					this.globalPost.push(i)
				} else if (i.type === 'international') {
					this.international.push(i)
				} else if (i.type === 'china') {
					this.china.push(i)
				}
			}
			this.renderList = this.globalPost
		},

		// 点击tab切换
		tabChange() {},

		// 设置各项标签的个数
		setupTabCount() {
			// 清空原来的count
			this.tabList.forEach((element) => {
				element.count = 0
			})

			//设置全部个数
			const entireItem = this.tabList[0]
			entireItem.count = this.dataSource.length

			// 设置其他标签的个数
			this.dataSource.forEach((element) => {
				const status = element.result
				const item = this.tabList.find((item) => {
					return item.status === status
				})
				item.count++
			})
		},

		statusTitle(status) {
			const item = this.tabList.find((item) => {
				return item.status === status
			})
			return item.title
		},

		//根据国家代码查询国家信息
		findCountryInfoByCode(code) {
			const item = countryList.find((item) => {
				return item.short === code
			})
			return item
		},

		// 点击enter
		onPressEnter(e, index) {
			if (index === this.textAreaForm.domains.length - 1) {
				// 如果光标在最后一个input,创建新的input
				this.textAreaForm.domains.push({
					value: '',
					key: index + 1,
				})
			} else {
				// 聚焦到最后一个input
				this.handleFocus()
			}
		},

		// 删除input
		removeInput(item) {
			if (this.textAreaForm.domains.length > 1) {
				let index = this.textAreaForm.domains.indexOf(item)
				if (index !== -1) {
					this.textAreaForm.domains.splice(index, 1)
				}
			}
		},

		clickInput(e) {
			e.stopPropagation()
		},

		showModal() {
			this.modalVisible = true
		},
		hideModal() {
			this.modalVisible = false
		},

		clickTabs(key) {
			if (key === 1) {
				this.renderList = this.globalPost
			} else if (key === 2) {
				this.renderList = this.international
			} else {
				this.renderList = this.china
			}
		},

		// 本地搜索
		handleSearch(e) {
			this.searchList = []
			for (let i of this.carriers) {
				if (i.name.includes(e.target.value) || i.enName.includes(e.target.value)) {
					this.searchList.push(i)
				}
			}
		},

		// 网络查询
		handleNetSearch() {
			if (this.selectCarrierItem) {
				const item = this.textAreaForm.domains[0]
				if (item.trackNum.length) {
					let params = []
					for (let i of this.textAreaForm.domains) {
						params.push({
							trackNum: i.trackNum,
							code: this.selectCarrierItem.code,
						})
					}
					console.log('params', params)
					this.loadData(params)
				} else {
					this.$message.error('请输入追踪号！')
				}
			} else {
				this.$message.error('请先选择渠道商！')
			}
		},

		clickCarrierItem(item) {
			this.selectCarrierItem = item
			this.hideModal()
		},
	},
	computed: {
		displaySource() {
			if (-1 === this.activeKey) {
				// 选择的全部
				return this.dataSource
			}

			const source = []
			this.dataSource.forEach((element) => {
				if (element.result === this.activeKey) {
					source.push(element)
				}
			})
			return source
		},
	},
	filters: {
		tabTitle(item) {
			return `${item.title}(${item.count})`
		},
	},
	directives: {
		focus: {
			inserted: function(el, { value }) {
				if (value) {
					el.focus()
				}
			},
		},
	},
}
</script>

<style lang="less" scoped>
.track-result {
	background: #f1f3fa;
	height: 100%;
	.header {
		padding: 0 24px;
		height: 60px;
		line-height: 60px;
		.title {
			font-size: 20px;
			font-weight: 500;
			color: #4a4a4a;
			margin-left: 10px;
		}
		.desc {
			font-size: 14px;
			font-weight: 400;
			margin-left: 10px;
			opacity: 0.8;
		}
	}
	.wrapper {
		display: flex;
		height: calc(100% - 60px);
		.content {
			flex: 3;
			padding: 0 24px 24px;
			overflow: auto;
			.tab {
				margin-top: 10px;
			}
			.item-header {
				.middle {
					display: flex;
					align-items: center;
					height: 44px;
					img {
						width: 20px;
						height: 10px;
						margin: 0 25px;
					}
				}
				.right {
					height: 44px;
					line-height: 44px;
				}
				.title {
					font-size: 16px;
					font-weight: 700;
					color: #4a4a4a;
					margin-bottom: 15px;
				}
			}
			.secLine {
				display: block;
			}
			.no-result {
				color: #999;
				margin-bottom: 25px;
			}
			.islatest {
				color: #1890ff;
			}
			.no-data {
				font-size: 16px;
				font-weight: 500;
			}
		}
		.search {
			background: #fff;
			flex: 1;
		}
	}

	.search {
		padding: 0 15px;
		.form {
			height: 340px;
			background: #ffffff;
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
			border-radius: 5px;
			border: 1px solid #979797;
			/deep/ input:focus {
				border-color: transparent !important;
				outline: 1px solid transparent !important;
				&:focus {
					border-color: transparent;
				}
			}
			/deep/ .ant-input,
			.ant-input:focus {
				border-color: transparent;
				outline: 1px solid transparent !important;
				box-shadow: none;
			}
			/deep/ .ant-form-item-children {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.delete-button {
				margin-right: 10px;
				font-size: 16px;
				cursor: pointer;
			}
			/deep/ .ant-form-item {
				margin-bottom: 0;
			}
		}
		.search-bar {
			display: flex;
			margin-top: 15px;
			.choose-btn,
			.search-btn {
				flex: 1;
				height: 60px;
			}
			.choose-btn {
				.chinese-name,
				.en-name {
					font-size: 16px;
					font-weight: bolder;
					white-space: nowrap;
					overflow: hidden;
					color: #333;
					text-overflow: ellipsis;
					margin-left: 5px;
				}
				.en-name {
					font-weight: normal;
				}
			}
			.search-btn {
				margin-left: 10px;
			}
		}
	}
}

/deep/ .ant-modal-content {
	border-radius: 5px;
	.ant-modal-body {
		padding: 0;
		min-height: 450px;
		.modal-header {
			height: 60px;
			padding: 0 20px;
			box-sizing: border-box;
			background: #0062b3;
			border-radius: 5px;
			font-size: 20px;
			color: #fff;
			.right {
				.cancel-btn {
					width: 30px;
					height: 30px;
					display: inline-block;
					margin-left: 20px;
					cursor: pointer;
				}
			}
			.search-input {
				width: 249px;
				height: 30px;
				background: #ffffff;
				border-radius: 20px;
				border: 1px solid #979797;
			}
		}
	}
	.container {
		/deep/ .ant-tabs-nav-wrap {
			height: 54px;
		}
		.ant-tabs-bar {
			margin: 0;
		}
		.pane-content {
			width: 100%;
			padding: 13px;

			box-sizing: border-box;
			.auto-match-btn {
				width: 134px;
				height: 25px;
				background: #ff008c;
				border-radius: 5px;
				border: 1px solid #ff008c;
				color: #fff;
				margin-bottom: 40px;
			}
			.customer-list {
				width: 100%;
				height: 300px;
				overflow-y: auto;
				flex-wrap: wrap;
				.customer-item {
					cursor: pointer;
					width: 25%;
					&:hover {
						.title {
							border-bottom: 1px solid #000;
						}
					}
					.customer-img {
						width: 34px;
						height: 34px;
						margin-right: 10px;
					}
					.title {
						font-size: 14px;
					}
				}
			}
		}
	}
}
</style>
